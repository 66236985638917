"use client";
// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// const auth = useSelector((state) => state.auth);

const baseUrl = "/api/";

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // please do not send any headers except for multipart post
    // you can set headers here if you want more headers
    // do not send any headers since browser will automatically handle multipart headers & content
    if (!headers.has("Content-Type")) {
      // const cookies = getCookie("seller_ref_id");
      headers.set("Content-Type", "application/json");
      // headers.set("seller-ref-id", `${cookies}`);
    } else {
      headers.delete("Content-Type");
    }
    const token = getState().auth.token;

    if (token) {
      // if (auth.token) {
      headers.set("authorization", token);
      // headers.set("authorization", auth.token);
    }
    return headers;
  },
});

// Define our single API slice object
export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: "api",
  // All of our requests will have URLs starting with '/fakeApi'
  baseQuery: baseQuery,
  // The "endpoints" represent operations and requests for this server
  endpoints: (builder) => ({}),
});
