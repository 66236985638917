"use client";
import { baseUrl, fetcher, options } from "@/utils/Api";
import { useRouter } from "next/navigation";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  RiCloseFill,
  RiHistoryFill,
  RiSearch2Line,
  RiSearchLine,
} from "react-icons/ri";
import useSWR from "swr";
import { suggestionData } from "../types";
import Link from "next/link";
import { useGetUsersSearchHistoryMutation } from "@/features/auth/authApiSlice";

const Searchbar3: React.FC<{
  token: String | null;
}> = ({ token }) => {
  const [searchTxt, setSearchTxt] = useState<string>("");

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [noTokenHistory, setNoTokenHistory] = useState<
    { term: string; _id?: string }[]
  >([]);

  const router = useRouter();
  const searchContainerRef = useRef<any>();

  const [getUsersSearchHistory, { data: usersSearchHistory }] =
    useGetUsersSearchHistoryMutation();

  useEffect(() => {
    const historyFromLocal = localStorage.getItem("history");
    const noTokenData =
      (historyFromLocal && JSON.parse(historyFromLocal)) || [];
    if (noTokenData?.length > 0) {
      setNoTokenHistory(noTokenData);
    }
  }, []);

  const {
    data: suggestionsData,
    isLoading: suggestionsLoading,
    mutate: suggestionsMutate,
  } = useSWR(
    {
      url: `${baseUrl}searchHistory/suggest?searchTerm=${searchTxt}`,
      headers: options,
    },
    fetcher,
    { revalidateOnMount: false, revalidateOnFocus: false }
  );
  const suggestions: suggestionData = suggestionsData?.data;

  const onInputFocus = () => {
    if (token && !usersSearchHistory?.data) {
      getUsersSearchHistory("");
    }
    if (!isHistoryVisible) {
      setIsHistoryVisible(true);
    }
  };

  const onInputBlur = () => {
    const container = document.getElementById("search_container3");
    if (container) {
      container.classList.remove("border-primary");
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
    suggestionsMutate(
      {
        url: `${baseUrl}searchHistory/suggest/?searchTerm=${searchTxt}`,
        headers: options,
      },
      { revalidate: false }
    );
  };
  const handlePushToLocal = (value: { term: string }) => {
    if (!token) {
      let localData = localStorage.getItem("history");
      // if (localData) {
      const parsed = (localData && JSON.parse(localData)) || [];
      const filterWithTerm = parsed.filter((x: { term: string }) => x.term);
      let merged: { term: string }[] = [...filterWithTerm, value];
      if (merged) {
        setNoTokenHistory(merged);
        localStorage.setItem("history", JSON.stringify(merged.splice(0, 8)));
      }
      // } else {
      //   const merged = [value];
      //   localStorage.setItem("history", JSON.stringify(merged));
      // }
    }
  };

  const handlePushToSearchHistory = (term: string) => {
    handlePushToLocal({ term });
  };

  const handleSearch = () => {
    router.push(`/search/${searchTxt}`);
    isHistoryVisible && setIsHistoryVisible(false);
    handlePushToLocal({ term: searchTxt });
  };

  const handleClearAll = () => {
    if (!token) {
      setNoTokenHistory([]);
      localStorage.removeItem("history");
      setIsHistoryVisible(false);
    }
  };

  const handleDeleteOneHistory = (idx: number) => {
    const filtered = noTokenHistory.filter((x, dltIdx) => dltIdx !== idx);
    setNoTokenHistory(filtered);
    localStorage.setItem("history", JSON.stringify(filtered));
  };

  const returnHistoryList = () => {
    const returnSuggestions =
      Array.isArray(suggestions) &&
      suggestions?.map((each, idx: number) => (
        <div
          className="flex items-center justify-between hover:bg-gray-100"
          key={each._id}
        >
          <Link
            href={`/search/${each._id}`}
            prefetch={false}
            className="block cursor-pointer px-4 py-2 flex-1 capitalize"
            onClick={() => setIsHistoryVisible(false)}
          >
            <div className="truncate w-4/5">{each._id}</div>
          </Link>
        </div>
      ));

    if (!token) {
      if (searchTxt === "" && noTokenHistory?.length > 0) {
        return (
          <>
            <div className="flex items-center justify-between border-b border-gray-100 px-4 py-2">
              <div className="text-gray-500">Search History</div>
              <button
                onClick={handleClearAll}
                className="hover:underline text-gray-500 hover:text-black"
              >
                Clear All
              </button>
            </div>
            <div>
              {noTokenHistory.map((each, idx: number) => (
                <div
                  className="flex items-center justify-between hover:bg-gray-100"
                  key={each._id}
                >
                  <Link
                    href={`/search/${each.term}`}
                    prefetch={false}
                    onClick={() => handlePushToSearchHistory(each.term)}
                    className="fslink block cursor-pointer px-4 py-2 flex-1 capitalize"
                  >
                    <div className="truncate w-4/5">{each.term}</div>
                  </Link>
                  <div
                    onClick={() => handleDeleteOneHistory(idx)}
                    className="cursor-pointer mr-2 opacity-60"
                  >
                    <RiCloseFill />
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      } else if (searchTxt !== "" && suggestions?.length > 0) {
        return returnSuggestions;
      }
    } else {
      if (searchTxt === "" && usersSearchHistory?.data?.length > 0) {
        return usersSearchHistory.data.map(
          (each: { _id: string; term: string }) => (
            <div
              className="flex items-center justify-between hover:bg-gray-100"
              key={each._id}
            >
              <RiHistoryFill className="ml-4" />
              <Link
                href={`/search/${each._id}`}
                prefetch={false}
                className="block cursor-pointer px-4 py-2 flex-1 capitalize"
                onClick={() => setIsHistoryVisible(false)}
              >
                <div className="truncate w-4/5">{each.term}</div>
              </Link>
            </div>
          )
        );
      } else if (searchTxt !== "" && suggestions?.length > 0) {
        return returnSuggestions;
      }
    }
    return <></>;
  };

  useEffect(() => {
    const handleClickOutSide = (event: any) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        isHistoryVisible && setIsHistoryVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () =>
      document.removeEventListener("click", handleClickOutSide, true);
  }, [searchContainerRef, isHistoryVisible]);

  return (
    <div className="flex-1 flex justify-center">
      <div
        className="lg:mx-8 relative max-w-xl mx-auto w-full"
        ref={searchContainerRef}
      >
        <div className="relative flex items-center justify-center w-full">
          <input
            className="outline-none text-sm py-2 px-3 w-full border border-gray-300 "
            placeholder="Search for products..."
            value={searchTxt}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onChange={handleChangeSearch}
          />
          {searchTxt !== "" && (
            <button
              type="button"
              className="px-3 text-gray-400 hover:text-gray-500 flex justify-center items-center absolute right-8 mt-[2px] h-10"
              onClick={() => {
                setSearchTxt("");
              }}
            >
              <RiCloseFill className="text-base md:text-2xl" />
            </button>
          )}
          <button
            type="button"
            className="right-3 absolute mt-1"
            title="Search"
            aria-label="Search"
            onClick={handleSearch}
          >
            <RiSearchLine className="text-base md:text-2xl text-secondary" />
          </button>
        </div>
        {isHistoryVisible && (
          <div className="bg-white absolute text-sm left-0 right-0 top-12 shadow overflow-auto">
            {returnHistoryList()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Searchbar3;
