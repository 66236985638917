"use client";
import { baseUrl, fetcher, options } from "@/utils/Api";
import { APP_NAME } from "@/utils/constans";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { RiCloseFill, RiHistoryFill, RiSearch2Line } from "react-icons/ri";
import useSWR from "swr";
import { suggestionData } from "../types";
import { useGetUsersSearchHistoryMutation } from "@/features/auth/authApiSlice";

const SearchBar2: React.FC<{ token: string | null }> = ({ token }) => {
  const [searchTxt, setSearchTxt] = useState<string>("");
  const [opened, { open, close }] = useDisclosure(false);

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [noTokenHistory, setNoTokenHistory] = useState<
    { term: string; _id?: string }[]
  >([]);

  const router = useRouter();
  const searchContainerRef = useRef<any>();

  const [getUsersSearchHistory, { data: usersSearchHistory }] =
    useGetUsersSearchHistoryMutation();

  useEffect(() => {
    const historyFromLocal = localStorage.getItem("history");
    const noTokenData =
      (historyFromLocal && JSON.parse(historyFromLocal)) || [];
    if (noTokenData?.length > 0) {
      setNoTokenHistory(noTokenData);
    }
  }, []);

  const {
    data: suggestionsData,
    isLoading: suggestionsLoading,
    mutate: suggestionsMutate,
  } = useSWR(
    {
      url: `${baseUrl}searchHistory/suggest?searchTerm=${searchTxt}`,
      headers: options,
    },
    fetcher,
    { revalidateOnMount: false, revalidateOnFocus: false }
  );
  const suggestions: suggestionData = suggestionsData?.data;

  const onInputFocus = () => {
    if (token && !usersSearchHistory?.data) {
      getUsersSearchHistory("");
    }
    if (!isHistoryVisible) {
      setIsHistoryVisible(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
    suggestionsMutate(
      {
        url: `${baseUrl}searchHistory/suggest?searchTerm=${searchTxt}`,
        headers: options,
      },
      { revalidate: false }
    );
  };

  const handleCloseModal = () => {
    close();
    setSearchTxt("");
  };

  const handlePushToLocal = (value: { term: string }) => {
    if (!token) {
      let localData = localStorage.getItem("history");
      // if (localData) {
      const parsed = (localData && JSON.parse(localData)) || [];
      const filterWithTerm = parsed.filter((x: { term: string }) => x.term);
      let merged: { term: string }[] = [...filterWithTerm, value];
      if (merged) {
        setNoTokenHistory(merged);
        localStorage.setItem("history", JSON.stringify(merged.splice(0, 8)));
      }
      // } else {
      //   const merged = [value];
      //   localStorage.setItem("history", JSON.stringify(merged));
      // }
    }
  };

  const handleGoToSearch = () => {
    router.push(`/search/${searchTxt}`);
    isHistoryVisible && setIsHistoryVisible(false);
    handlePushToLocal({ term: searchTxt });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      close();
      setSearchTxt("");
      window.location.replace(`/search/${searchTxt}`);
    }
  };

  const handlePushToSearchHistory = (term: string) => {
    handlePushToLocal({ term });
  };

  const handleClearAll = () => {
    if (!token) {
      setNoTokenHistory([]);
      localStorage.removeItem("history");
      setIsHistoryVisible(false);
    }
  };

  const handleDeleteOneHistory = (idx: number) => {
    const filtered = noTokenHistory.filter((x, dltIdx) => dltIdx !== idx);
    setNoTokenHistory(filtered);
    localStorage.setItem("history", JSON.stringify(filtered));
  };

  const returnHistoryList = () => {
    const returnSuggestions =
      Array.isArray(suggestions) &&
      suggestions?.map((each, idx: number) => (
        <div
          className="flex items-center justify-between hover:bg-gray-100"
          key={each._id}
        >
          <Link
            href={`/search/${each._id}`}
            prefetch={false}
            className="fslink block cursor-pointer px-4 py-2 flex-1 capitalize"
            onClick={() => setIsHistoryVisible(false)}
          >
            <div className="truncate w-4/5">{each._id}</div>
          </Link>
        </div>
      ));

    if (!token) {
      if (searchTxt === "" && noTokenHistory?.length > 0) {
        return (
          <>
            <div className="flex items-center justify-between border-b border-gray-100 px-4 py-2">
              <div className="text-gray-500">Search History</div>
              <button
                onClick={handleClearAll}
                className="hover:underline text-gray-500 hover:text-black"
              >
                Clear All
              </button>
            </div>
            <div>
              {noTokenHistory.map((each, idx: number) => (
                <div
                  className="flex items-center justify-between hover:bg-gray-100"
                  key={each._id}
                >
                  <Link
                    href={`/search/${each.term}`}
                    prefetch={false}
                    onClick={() => handlePushToSearchHistory(each.term)}
                    className="fslink block cursor-pointer px-4 py-2 flex-1 capitalize"
                  >
                    <div className="truncate w-4/5">{each.term}</div>
                  </Link>
                  <div
                    onClick={() => handleDeleteOneHistory(idx)}
                    className="cursor-pointer mr-2 opacity-60"
                  >
                    <RiCloseFill />
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      } else if (searchTxt !== "" && suggestions?.length > 0) {
        return returnSuggestions;
      }
    } else {
      if (searchTxt === "" && usersSearchHistory?.data?.length > 0) {
        return usersSearchHistory.data.map(
          (each: { _id: string; term: string }) => (
            <div
              className="flex items-center justify-between hover:bg-gray-100"
              key={each._id}
            >
              <RiHistoryFill className="ml-4" />
              <Link
                href={`/search/${each._id}`}
                prefetch={false}
                className="fslink block cursor-pointer px-4 py-2 flex-1 capitalize"
                onClick={() => setIsHistoryVisible(false)}
              >
                <div className="truncate w-4/5">{each.term}</div>
              </Link>
            </div>
          )
        );
      } else if (searchTxt !== "" && suggestions?.length > 0) {
        return returnSuggestions;
      }
    }
    return <></>;
  };

  useEffect(() => {
    const handleClickOutSide = (event: any) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        isHistoryVisible && setIsHistoryVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutSide, true);
    return () =>
      document.removeEventListener("click", handleClickOutSide, true);
  }, [searchContainerRef, isHistoryVisible]);

  return (
    <>
      <div className="flex-1 relative mr-2 lg:mr-0 " ref={searchContainerRef}>
        <div className=" relative">
          <input
            placeholder={`Search for Product in ${APP_NAME}`}
            value={searchTxt}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            onFocus={onInputFocus}
            onBlur={close}
            className="outline-none border border-gray-300 px-3 py-2 focus:border-primary w-full rounded pr-7"
          />
          <div
            className=" absolute top-4 right-2 z-20 hover:text-primary text-sm cursor-pointer"
            onClick={handleGoToSearch}
          >
            <FaSearch />
          </div>
          {isHistoryVisible && (
            <div className="w-full bg-white absolute text-sm left-0 right-0 shadow overflow-auto">
              {returnHistoryList()}
            </div>
          )}
        </div>
        {/* {opened && (
          <div className="absolute z-50 w-full ">
            {suggestions?.length > 0 && (
              <div className="h-96 mt-4 overflow-y-auto border  bg-gray-100 shadow-lg rounded">
                <div className="flex flex-col w-full">
                  {suggestions.map((s, idx) => (
                    <Link
                      key={`${s._id}-${idx}`}
                      prefetch={false}
                      href={`/search/${s._id}`}
                      onClick={() => {
                        setTimeout(() => {
                          close();
                          setSearchTxt("");
                        }, 300);
                      }}
                      className="py-1 hover:bg-gray-200 flex items-center gap-2 pl-2"
                    >
                      <RiSearch2Line className="text-gray-600 text-xl" />
                      {s._id}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        )} */}
      </div>
    </>
  );
};

export default SearchBar2;
