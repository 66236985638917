"use client";

import React from "react";
import { store } from "./store";
import { Provider } from "react-redux";
import { SellerConfigsProvider } from "@/context/Main";

export function Providers({
  children,
  sellerConfigs,
}: {
  children: React.ReactNode;
  sellerConfigs: any;
}) {
  return (
    <Provider store={store}>
      <SellerConfigsProvider sellerConfigs={sellerConfigs}>
        {children}
      </SellerConfigsProvider>
    </Provider>
  );
}
