"use client";
import Button from "@/components/Button";
import { makeSelectToken, makeSelectUsers } from "@/features/auth/authSlice";
import { Drawer } from "@mantine/core";
import { signIn, signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import React from "react";
import { RiMenu2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import MobileViewMenu from "../internalComponents/MobileViewMenu";
import { Child } from "../types";

const HeaderMenu: React.FC<{
  topMenu: [
    {
      is_internal: boolean;
      title: string;
      url: string;
      image: {
        path: string;
      };
      target: string;
      menu_sch_id: string;
      child_menu: Child[];
    },
  ];
  logo: any;
}> = ({ topMenu, logo }) => {
  const navigate = useRouter();
  const token = useSelector(makeSelectToken);
  const user = useSelector(makeSelectUsers);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleLogin = () => {
    if (!token) {
      signIn();
      setOpenDrawer(false);
    }
  };

  const handleLogout = () => {
    signOut({ callbackUrl: "/login" });
  };

  return (
    <>
      <button
        onClick={() => setOpenDrawer(true)}
        className="w-12 h-12 flex items-center justify-center"
      >
        <RiMenu2Fill className="text-xl" />
      </button>
      <Drawer
        size="xs"
        opened={openDrawer}
        onClose={() => setOpenDrawer(false)}
        position="left"
      >
        <MobileViewMenu
          child={topMenu}
          drawerClose={() => setOpenDrawer(false)}
        />
      </Drawer>
    </>
  );
};

export default HeaderMenu;
