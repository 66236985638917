"use client";
import {
  useFetchSellerDetailsMutation,
  useLogoutUserMutation,
} from "@/features/auth/authApiSlice";
import {
  makeSelectSellerDetails,
  makeSelectToken,
  makeSelectUsers,
  updateInitialState,
} from "@/features/auth/authSlice";
import { IMAGE_BASE } from "@/utils/Api";
import { Menu } from "@mantine/core";
import { signIn, signOut } from "next-auth/react";
import ImageComponent from "@/components/Image";
import Link from "next/link";
import { useEffect, useState } from "react";
import {
  RiArrowDownSFill,
  RiBook3Line,
  RiLogoutBoxLine,
  RiShoppingCart2Line,
  RiStarLine,
  RiTicket2Line,
  RiUserLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { PiUserCircleLight } from "react-icons/pi";

const LoginActions = ({
  session,
}: {
  session: {
    user: {
      name: string;
      image: {
        path: string;
      };
    } | null;
    access_token: string | null;
    login: any;
  };
}) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  // rtk part;
  const [logout] = useLogoutUserMutation();

  const [sellerData, { data }] = useFetchSellerDetailsMutation();

  useEffect(() => {
    sellerData;
  }, []);

  // auth state selectors;
  const token = useSelector(makeSelectToken);
  const clientUsers = useSelector(makeSelectUsers);
  const sessionUsers = session?.user ?? null;

  const sellerDetails = useSelector(makeSelectSellerDetails);

  useEffect(() => {
    if (!token && !clientUsers) {
      dispatch(
        updateInitialState({
          users: sessionUsers,
          token: session?.access_token,
        })
      );
    }
  }, [session?.access_token]);

  const handleLogout = () => {
    logout(null);
    signOut({ redirect: false, callbackUrl: "/login" }).then(() => {
      window.location.replace("/login");
    });
    setMenuOpen(false);
  };

  const handleLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!session?.access_token) {
      signIn();
    }
  };

  return (
    <>
      {/* For Desktop */}
      <Menu
        styles={{
          item: {
            padding: "6px",
            borderRadius: "0px",
          },
        }}
        position="bottom-end"
        shadow="lg"
        width={200}
        opened={menuOpen}
        onChange={setMenuOpen}
      >
        <Menu.Target>
          {session?.access_token ? (
            <div>
              {sessionUsers?.image?.path ? (
                <button className="flex items-center gap-2">
                  <ImageComponent
                    src={`${IMAGE_BASE}${sessionUsers.image.path}`}
                    alt={sessionUsers?.name}
                    sizes=""
                    className="rounded-full object-cover hover:shadow-md"
                  />
                  <RiArrowDownSFill />
                </button>
              ) : (
                <button className="flex items-center gap-1 hover:text-primary">
                  <PiUserCircleLight className="text-2xl" />
                  <RiArrowDownSFill className="text-lg" />
                </button>
              )}
            </div>
          ) : (
            <button
              onClick={handleLogin}
              className="hover:text-primary text-2xl hidden lg:flex items-center"
            >
              <PiUserCircleLight />
            </button>
          )}
        </Menu.Target>
        <>
          {session?.access_token ? (
            <Menu.Dropdown>
              {menuActions.map((each) => (
                <Link
                  key={each.path}
                  href={each.path}
                  prefetch={false}
                  onClick={() => setMenuOpen(false)}
                  className="flex gap-3 p-2 font-medium items-center text-gray-700 hover:text-secondary cursor-pointer hover:bg-gray-100 duration-100"
                >
                  <span className="text-base rounded-full bg-gray-100 p-1.5">
                    {each.icon}
                  </span>
                  <span className="text-sm">{each.label}</span>
                </Link>
              ))}
              <span
                onClick={handleLogout}
                className="flex gap-3 p-2 font-medium items-center text-gray-700 hover:text-secondary cursor-pointer hover:bg-gray-100 duration-100"
              >
                <span className="text-base rounded-full bg-gray-100 p-1.5">
                  <RiLogoutBoxLine />
                </span>
                <span className="text-sm">Logout</span>
              </span>
            </Menu.Dropdown>
          ) : null}
        </>
      </Menu>

      {/* For Mobile */}
      <button
        onClick={handleLogin}
        className="flex lg:hidden gap-1 flex-col items-center justify-center"
      >
        <RiUserLine className="text-xl" />
      </button>
    </>
  );
};

export default LoginActions;

const menuActions = [
  { label: "My Profile", path: "/user/profile", icon: <RiUserLine /> },
  { label: "My Address Book", path: "/user/address", icon: <RiBook3Line /> },
  {
    label: "My Orders",
    path: "/user/my-orders",
    icon: <RiShoppingCart2Line />,
  },
];
