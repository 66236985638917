"use client";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { authApiSlice } from "./authApiSlice";
import { AccessInjectorProps } from "@/app/(app)/types";

const initialState: stateProps = {
  token: null,
  users: null,
  notifications: [],
  cart_count: 0,
  sellerDetails: {},
  seller_access: [],
  seller_ref_id: "",
};

type stateProps = {
  token: null | string;
  users: null | userType;
  notifications: Array<{ key: string }>;
  cart_count: number;
  seller_access: AccessInjectorProps["seller_access"];
  seller_ref_id: AccessInjectorProps["seller_ref_id"];
  sellerDetails: any;
};

export type userType = {
  image?: { path: string };
  cart_id?: string;
  email?: string;
  email_verified: boolean;
  id: string;
  name: string;
  otp_verified: boolean;
  role_type: string;
  mobile_prefix?: string;
  mobile_no?: string;
  date_of_birth?: any;
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateInitialState: (state, action) => {
      state.token = action.payload.token;
      state.users = action.payload.users;
    },
    enqueueSnackbar: (state, action) => {
      state.notifications = [...state.notifications, { ...action.payload }];
    },
    removeSnackbar: (state, action) => {
      state.notifications = state.notifications.filter(
        (each) => each.key !== action.payload
      );
    },
    increaseCartNumber: (state, action) => {
      state.cart_count = state.cart_count + 1;
    },
    decreaseCartNumber: (state, action) => {
      state.cart_count = state.cart_count - 1;
    },
    updateCartNumber: (state, action) => {
      state.cart_count = action.payload;
    },
    updateSellerRefDetails: (state, { payload }) => {
      state.seller_access = payload.seller_access;
      state.seller_ref_id = payload.seller_ref_id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApiSlice.endpoints.updateProfile.matchFulfilled,
        (state, action) => {
          state.users = action.payload?.data ?? initialState.users;
          state.token = action.payload?.token;
        }
      )
      .addMatcher(
        authApiSlice.endpoints.loadCartNumber.matchFulfilled,
        (state, action) => {
          state.cart_count = action.payload.data?.itemCount ?? 0;
        }
      )
      .addMatcher(
        authApiSlice.endpoints.fetchCustomerGuestToken.matchFulfilled,
        (state, action) => {
          sessionStorage.setItem(
            "customer_guest_token",
            action.payload.data.customer_guest_token
          );
        }
      )
      .addMatcher(
        authApiSlice.endpoints.fetchSellerDetails.matchFulfilled,
        (state, action) => {
          state.sellerDetails = action.payload.data;
        }
      );
  },
});

export const {
  updateInitialState,
  enqueueSnackbar,
  removeSnackbar,
  increaseCartNumber,
  decreaseCartNumber,
  updateCartNumber,
  updateSellerRefDetails,
} = authSlice.actions;

export default authSlice.reducer;

const selectDomain = (state: { auth: stateProps }) =>
  state.auth || initialState;

export const makeSelectNotifications = createSelector(
  selectDomain,
  (state) => state.notifications
);

export const makeSelectUsers = createSelector(
  selectDomain,
  (state) => state.users
);

export const makeSelectToken = createSelector(
  selectDomain,
  (state) => state.token
);

export const makeSelectCartCount = createSelector(
  selectDomain,
  (state) => state.cart_count
);

export const makeSelectSellerDetails = createSelector(
  selectDomain,
  (state) => state.sellerDetails
);
