"use client";

import {
  MantineProvider as MantineProviderMain,
  createTheme,
} from "@mantine/core";
import { themeCompoentObj, theme } from "./theme";
import { SellerConfigInfo } from "../../types";
import { ReactNode } from "react";

export default function MantineProvider({
  web_basic,
  children,
}: {
  web_basic: SellerConfigInfo["data"]["web_basic"];
  children: ReactNode;
}) {
  if (web_basic?.primary_color && web_basic?.secondary_color) {
    const overrideTheme = createTheme({
      components: themeCompoentObj,
      primaryColor: "primary",
      colors: {
        primary: [
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
          `${web_basic.primary_color}`,
        ],
        secondary: [
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
          `${web_basic.secondary_color}`,
        ],
      },
    });

    return (
      <MantineProviderMain theme={overrideTheme}>
        {children}
      </MantineProviderMain>
    );
  }

  return <MantineProviderMain theme={theme}>{children}</MantineProviderMain>;
}
