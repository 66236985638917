"use client";
import React, { useEffect } from "react";
import { AccessInjectorProps } from "../types";
import { useDispatch } from "react-redux";
import { updateSellerRefDetails } from "@/features/auth/authSlice";

const AccessInjector: React.FC<AccessInjectorProps> = ({
  children,
  seller_access,
  seller_ref_id,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (seller_ref_id && seller_access) {
      dispatch(
        updateSellerRefDetails({
          seller_access,
          seller_ref_id,
        })
      );
    }
  }, [seller_ref_id, seller_access]);

  return <>{children}</>;
};

export default AccessInjector;
