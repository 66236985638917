"use client";
import { apiSlice } from "@/redux/apiSlice";
import { checkAndReturnApiWithCustomerGT } from "@/utils/session";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    logoutUser: builder.mutation({
      query: () => `user/logout`,
    }),
    updateProfile: builder.mutation({
      query: (formData: any) => ({
        url: `user/profile`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    mobileVerify: builder.mutation({
      query: (formData) => ({
        url: `user/mobile/verify`,
        method: "POST",
        body: formData,
      }),
    }),
    sendOtp: builder.mutation({
      query: (formData) => ({
        url: `user/mobile`,
        method: "POST",
        body: formData,
      }),
    }),
    loadCartNumber: builder.mutation({
      query: () => {
        return {
          url: checkAndReturnApiWithCustomerGT("cart/count"),
        };
      },
    }),
    submitContactForm: builder.mutation({
      query: (formData) => ({
        url: `contact`,
        method: "POST",
        body: formData,
      }),
    }),
    fetchCustomerGuestToken: builder.mutation({
      query: () => `cart/fetch/token`,
    }),

    fetchSellerDetails: builder.mutation({
      query: () => `sellerInfo/seller/detail`,
    }),
    getUsersSearchHistory: builder.mutation({
      query: () => `searchHistory`,
    }),
  }),
  overrideExisting: true,
});

export const {
  useLogoutUserMutation,
  useUpdateProfileMutation,
  useMobileVerifyMutation,
  useSendOtpMutation,
  useLoadCartNumberMutation,
  useSubmitContactFormMutation,
  useFetchCustomerGuestTokenMutation,
  useFetchSellerDetailsMutation,
  useGetUsersSearchHistoryMutation,
} = authApiSlice;
