"use client";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import authSlice from "@/features/auth/authSlice";

const allReducers = combineReducers({
  auth: authSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});

function createReducer(asyncReducers) {
  return combineReducers({
    auth: authSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    ...asyncReducers,
  });
}

store.asyncReducers = {};

export const useInjectReducer = (key, asyncReducers) => {
  if (store.asyncReducers[key] !== undefined) {
    return;
  }
  store.asyncReducers[key] = asyncReducers;
  store.replaceReducer(createReducer(store.asyncReducers));
};
