"use client";
import { Affix, Transition, rem } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { RiArrowUpSLine } from "react-icons/ri";
import { useState, useEffect } from "react";

const AffixContainer = () => {
  //mantine scroll hook
  const [scroll, scrollTo] = useWindowScroll();
  const [winWidth, setWinWidth] = useState(0);

  useEffect(() => {
    setWinWidth(window.innerWidth);
  }, []);

  return (
    <Affix
      position={{ bottom: winWidth > 769 ? rem(20) : rem(60), right: rem(40) }}
    >
      <Transition transition="slide-up" mounted={scroll.y > 50}>
        {(transitionStyles) => (
          <button
            style={{
              ...transitionStyles,
              backgroundColor: "#323232",
              fontWeight: "normal",
            }}
            className="p-1 text-white"
            onClick={() => scrollTo({ y: 0 })}
          >
            <RiArrowUpSLine className="text-3xl" />
          </button>
        )}
      </Transition>
    </Affix>
  );
};

export default AffixContainer;
