"use client";
import { createTheme } from "@mantine/core";
import colors from "../../../../colors";

export const themeCompoentObj = {
  // Add Mantine component overrides here
  Button: {
    styles: () => ({
      root: {
        borderRadius: 0,
        height: 35,
      },
      label: {
        fontWeight: "normal",
        fontSize: "14px",
      },
    }),
  },
  PasswordInput: {
    styles: () => ({
      input: {
        height: 40,
        borderRadius: 0,
        border: `1px solid ${colors.gray[300]}`,
        fontSize: "14px",
      },
      label: {
        fontSize: "14px",
        marginBottom: 2,
      },
    }),
  },
  TextInput: {
    styles: {
      input: {
        height: 40,
        borderRadius: 0,
        border: `1px solid ${colors.gray[300]}`,
        fontSize: "14px",
      },
      label: {
        marginBottom: 2,
      },
    },
  },
  LoadingOverlay: {
    styles: () => ({
      root: {
        "& svg": {
          fill: "#FF9283",
        },
      },
    }),
  },
  Autocomplete: {
    styles: () => ({
      input: {
        height: 54,
        borderRadius: 0,
        border: "1px solid #e6e6e6",
        "&:focus": {
          border: "1px solid #003893",
        },
      },
      label: {
        marginBottom: 8,
      },
    }),
  },
  // Indicator: {
  //   styles: () => ({
  //     indicator: {
  //       backgroundColor: "#00000",
  //     },
  //   }),
  // },
  Textarea: {
    styles: () => ({
      input: {
        borderRadius: 3,
        border: `1px solid ${colors.gray[300]}`,
        "&:focus": {
          border: "1px solid #000",
        },
      },
    }),
  },
  Checkbox: {
    styles: () => ({
      input: {
        cursor: "pointer",
      },
      label: {
        cursor: "pointer",
      },
    }),
  },
  Divider: {
    styles: () => ({
      root: {
        borderColor: "#DBDCDC",
      },
    }),
  },
  Image: {
    styles: () => ({
      imageWrapper: {
        overflow: "hidden",
        "& img": {
          transition: "transform .5s ease;",
          "&:hover": {
            transform: "scale(1.1)",
          },
        },
      },
    }),
  },
  Select: {
    styles: () => ({
      input: {
        height: 30,
        borderRadius: 3,
        fontSize: "12px",
        border: `1px solid ${colors.gray[300]}`,
      },
      label: {
        marginBottom: 2,
      },
      item: {
        fontSize: "12px !important",
      },
    }),
  },
  MultiSelect: {
    styles: () => ({
      input: {
        height: 40,
        borderRadius: 0,
        border: "1px solid #828282",
        "&:focus-within": {
          border: "1px solid #E6C6D1",
        },
      },
      label: {
        marginBottom: 8,
      },
      values: {
        height: 56,
      },
      searchInput: {
        height: 56,
        "&:focus-visible": {
          outline: "none",
        },
      },
    }),
  },
  Modal: {
    styles: () => ({
      root: {
        zIndex: 1005,
      },
    }),
  },
  Radio: {
    styles: () => ({}),
  },
  Container: {
    defaultProps: {
      sizes: {
        xs: 540,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1200,
      },
    },
  },
};

export const theme = createTheme({
  components: {
    // Add Mantine component overrides here
    Button: {
      styles: () => ({
        root: {
          backgroundColor: colors.primary,
          borderRadius: 0,
          height: 35,
        },
        label: {
          fontWeight: "normal",
          fontSize: "14px",
        },
      }),
    },
    PasswordInput: {
      styles: () => ({
        input: {
          height: 40,
          borderRadius: 0,
          border: `1px solid ${colors.gray[300]}`,
          fontSize: "14px",
        },
        label: {
          fontSize: "14px",
          marginBottom: 2,
        },
      }),
    },
    TextInput: {
      styles: {
        input: {
          height: 40,
          borderRadius: 0,
          border: `1px solid ${colors.gray[300]}`,
          fontSize: "14px",
        },
        label: {
          marginBottom: 2,
        },
      },
    },
    LoadingOverlay: {
      styles: () => ({
        root: {
          "& svg": {
            fill: "#FF9283",
          },
        },
      }),
    },
    Autocomplete: {
      styles: () => ({
        input: {
          height: 54,
          borderRadius: 0,
          border: "1px solid #e6e6e6",
          "&:focus": {
            border: "1px solid #003893",
          },
        },
        label: {
          marginBottom: 8,
        },
      }),
    },
    // Indicator: {
    //   styles: () => ({
    //     indicator: {
    //       backgroundColor: "#00000",
    //     },
    //   }),
    // },
    Textarea: {
      styles: () => ({
        input: {
          borderRadius: 3,
          border: `1px solid ${colors.gray[300]}`,
          "&:focus": {
            border: "1px solid #000",
          },
        },
      }),
    },
    Checkbox: {
      styles: () => ({
        input: {
          cursor: "pointer",
        },
        label: {
          cursor: "pointer",
        },
      }),
    },
    Divider: {
      styles: () => ({
        root: {
          borderColor: "#DBDCDC",
        },
      }),
    },
    Image: {
      styles: () => ({
        imageWrapper: {
          overflow: "hidden",
          "& img": {
            transition: "transform .5s ease;",
            "&:hover": {
              transform: "scale(1.1)",
            },
          },
        },
      }),
    },
    Select: {
      styles: () => ({
        input: {
          height: 30,
          borderRadius: 3,
          fontSize: "12px",
          border: `1px solid ${colors.gray[300]}`,
        },
        label: {
          marginBottom: 2,
        },
        item: {
          fontSize: "12px !important",
        },
      }),
    },
    MultiSelect: {
      styles: () => ({
        input: {
          height: 40,
          borderRadius: 0,
          border: "1px solid #828282",
          "&:focus-within": {
            border: "1px solid #E6C6D1",
          },
        },
        label: {
          marginBottom: 8,
        },
        values: {
          height: 56,
        },
        searchInput: {
          height: 56,
          "&:focus-visible": {
            outline: "none",
          },
        },
      }),
    },
    Modal: {
      styles: () => ({
        root: {
          zIndex: 1005,
        },
        title: {
          color: colors.primary,
        },
      }),
    },
    Radio: {
      styles: () => ({}),
    },
    Container: {
      defaultProps: {
        sizes: {
          xs: 540,
          sm: 640,
          md: 768,
          lg: 1024,
          xl: 1200,
        },
      },
    },
  },
  primaryColor: "primary",
  colors: {
    primary: [
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
      `${colors.primary}`,
    ],
  },
});
