"use client";
import Link from "next/link";
import React, { FC } from "react";
import { MenuProps } from "../types";
import { usePathname } from "next/navigation";
import "./menu.css";

const TopMenu: FC<{
  child: MenuProps["child"][0];
}> = ({ child }) => {
  const pathname = usePathname();

  if (child.url?.includes("/")) {
    return (
      <div className="menu-item" key={child._id}>
        <Link
          href={child.url}
          prefetch={false}
          className={`menu text-black font-bold text-xs px-5 relative uppercase cursor-pointer ${
            pathname === child.url ? "select_indicator" : ""
          }`}
        >
          <span className="flex items-center gap-2 py-4 uppercase hover:text-blue-500">
            {child.title}
          </span>
        </Link>
        {child.child_menu && child.child_menu.length > 0 && (
          <ul className="menu-child text-base">
            {child.child_menu.map((menuItem: any) => (
              <li key={menuItem._id}>
                <a className="hover:text-blue-500" href={menuItem.url}>
                  {menuItem.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  return (
    <div
      className={`text-black font-bold text-xs px-5 relative uppercase cursor-pointer ${
        pathname === child.url ? "select_indicator" : ""
      }`}
      key={child._id}
    >
      <span className="flex items-center gap-2 py-4 uppercase  hover:text-primary">
        {child.title}
      </span>
    </div>
  );
};

export default TopMenu;
