"use client";
import { FC, ReactNode, createContext, useContext } from "react";
import { SellerConfigInfo } from "../../app/(app)/types";

const SellerConfigContext = createContext<SellerConfigInfo["data"] | null>(
  null
);

export const SellerConfigsProvider: FC<{
  children: ReactNode;
  sellerConfigs: SellerConfigInfo["data"];
}> = ({ children, sellerConfigs }) => {
  return (
    <>
      <SellerConfigContext.Provider value={sellerConfigs}>
        {children}
      </SellerConfigContext.Provider>
    </>
  );
};

export const useSellerConfigs = () => {
  const sellerConfigs = useContext(SellerConfigContext);
  return sellerConfigs;
};
