'use client';
import { IMAGE_BASE } from '@/utils/Api';
import Link from 'next/link';
import { Collapse } from '@mantine/core';
import { useState, Fragment } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Child, MenuProps } from '../types';
import ImageComponent from '@/components/Image';

const getChildElementMobileView = (parentObj: Child, drawerClose: () => void) => {
  const childContent: any = [];
  if (parentObj.child_menu.length) {
    parentObj.child_menu.map((childElement, index) => {
      childContent.push(
        <li key={index}>
          {childElement.is_internal ? (
            <>
              <Link className="flex items-center gap-2 py-1.5 text-sm font-semibold" prefetch={false} href={childElement.url} onClick={drawerClose}>
                <RiArrowRightSLine /> {childElement.title}
              </Link>
              {childElement.child_menu && childElement.child_menu.length ? <ul className="pl-4 lg:pl-0 lg:absolute">{getChildElementMobileView(childElement, drawerClose)}</ul> : null}
            </>
          ) : (
            <a className="text-xs sm:block relative my-auto md:ml-8 items-center tracking-wide hover:underline" href={childElement.url} target={childElement.target}>
              {childElement.title}
            </a>
          )}
        </li>,
      );
    });
    return childContent;
  }
};

interface MobileViewMenuProps extends MenuProps {
  drawerClose: () => void;
  child: any;
}

const MobileViewMenu: React.FC<MobileViewMenuProps> = ({ child, drawerClose }) => {
  const [openMenus, setOpenMenus] = useState<Array<number>>([]);

  const handlePushOpenIdx = (idx: number) => {
    setOpenMenus((prev) => {
      if (prev.includes(idx)) {
        return prev.filter((e) => e !== idx);
      } else {
        return [...prev, idx];
      }
    });
  };

  return (
    <>
      <div className="block lg:flex lg:flex-wrap md:justify-end">
        {child?.map((each: any, index: number) => {
          if (each.is_internal) {
            let navclass = 'py-2 text-sm relative my-auto lg:text-gray-500 md:py-0 topql lg:ml-8 hover:underline cursor-pointer';
            if (index === 0) navclass = 'py-2 text-sm my-auto lg:text-gray-500 md:py-0 hover:underline cursor-pointer';
            if (index === child.length - 1) navclass = 'py-2 text-sm relative topql my-auto lg:text-gray-500 md:py-0 lg:ml-8 hover:underline cursor-pointer';
            return (
              <Fragment key={`mobile-menu-${index}`}>
                <div className="flex justify-between items-center py-1.5 font-semibold">
                  {each.url == '' || each.url == '#' ? (
                    <button className={`${navclass} flex items-center gap-2 whitespace-nowrap  `}>{each.title}</button>
                  ) : (
                    <Link href={each.url} className={`${navclass} flex items-center gap-2 whitespace-nowrap `} onClick={drawerClose}>
                      {each.image?.path ? <ImageComponent imgObj={each.image.path} alt={each.title} size="15-15" /> : null}
                      {each.title}
                    </Link>
                  )}

                  {/* {each.child_menu?.length > 0 && (
                    <button
                      aria-label="open-category"
                      onClick={() => handlePushOpenIdx(index)}
                    >
                      <RiArrowRightSLine
                        className={`text-xl ${
                          openMenus.includes(index) ? "rotate-90" : ""
                        } duration-200`}
                      />
                    </button>
                  )} */}
                </div>
                {each.child_menu && each.child_menu.length > 0 && (
                  // <Collapse in={openMenus.includes(index)}>
                  <Collapse in={true}>
                    <ul className="menu-child--- relative text-sm">{getChildElementMobileView(each, drawerClose)}</ul>
                  </Collapse>
                )}
              </Fragment>
            );
          }
          return (
            <div key={index}>
              <a className="topql relative my-auto items-center text-xs hover:underline sm:block lg:ml-8" href={each.url} target={each.target}>
                {each.title}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MobileViewMenu;
