"use client";
import { useLoadCartNumberMutation } from "@/features/auth/authApiSlice";
import {
  makeSelectCartCount,
  makeSelectToken,
} from "@/features/auth/authSlice";
import { getCustomerGuestToken } from "@/utils/session";
import Link from "next/link";
import { useEffect, useState } from "react";
import { BsCart } from "react-icons/bs";
import { useSelector } from "react-redux";

const CartIcons = () => {
  const [loadCartNumber, { isLoading }] = useLoadCartNumberMutation();
  const [mounted, setMounted] = useState(false);

  const cartCount = useSelector(makeSelectCartCount);
  const token = useSelector(makeSelectToken);

  useEffect(() => {
    const customer_guest_token = getCustomerGuestToken();
    if (token || customer_guest_token) {
      loadCartNumber(null);
    }
    setMounted(true);
  }, [token]);

  if (!mounted) {
    return (
      <Link prefetch={false} href="/viewcart" aria-label="View Cart">
        <button className="text-xl hover:text-primary relative mt-1.5">
          <span className="absolute -top-2.5 text-xs bg-primary text-white rounded-full py-[1.5px] px-1.5 shadow">
            0
          </span>
          <BsCart />
        </button>
      </Link>
    );
  }

  return (
    <Link prefetch={false} href="/viewcart" aria-label="View Cart">
      <button className="text-xl hover:text-primary relative mt-1.5">
        <span className="absolute -top-2.5 text-xs bg-primary text-white rounded-full py-[1.5px] px-1.5 shadow">
          {cartCount}
        </span>
        <BsCart />
      </button>
    </Link>
  );
};

export default CartIcons;
