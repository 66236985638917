"use client";
import {
  makeSelectNotifications,
  removeSnackbar,
} from "@/features/auth/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(makeSelectNotifications);

  useEffect(() => {
    notifications?.map((each, index) => {
      if (each.options && each.options.variant) {
        toast[each.options.variant](each.message, {
          ...each.options,
        });
      } else {
        if (each.options) {
          toast(each.message, { ...each.options });
        } else {
          toast(each.message);
        }
      }

      dispatch(removeSnackbar(each.key));
    });
  }, [notifications]);

  return (
    <ToastContainer
      position="bottom-left"
      pauseOnHover={true}
      closeOnClick={true}
      autoClose={3000}
      hideProgressBar={true}
    />
  );
};

export default Notifier;
