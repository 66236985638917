export const getCustomerGuestToken = () => {
  const customer_id = sessionStorage.getItem("customer_guest_token");
  return customer_id;
};

export function returnGuestToken() {
  const customerGuestToken = getCustomerGuestToken();
  return customerGuestToken || null;
}

export function checkAndReturnApiWithCustomerGT(api: string) {
  return !returnGuestToken()
    ? api
    : `${api}?customer_guest_token=${returnGuestToken()}`;
}
